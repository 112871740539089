import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./TextInput.module.css";
import { ArrowUpCircleFill, Eye, EyeSlash } from "react-bootstrap-icons";
import Spinner from "../Spinner/Spinner";

const TextInput = ({
  value,
  setValue,
  onConfirmCB,
  placeholder = "",
  animationClass = null,
  type = "text",
  isLoading = null,
}) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && value.length > 0) {
      onConfirmCB();
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordHidden(!isPasswordHidden);
  };

  return (
    <div className={`d-flex ${styles.inputRow} ${animationClass}`}>
      <input
        type={type === "password" && isPasswordHidden ? "password" : "text"}
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        className={`form-control ${styles.taskInput}`}
        autoFocus
      />
      <div className={styles.iconsContainer}>
        {type === "password" && (
          <div
            className={`${styles.showPasswordIcon}`}
            onClick={togglePasswordVisibility}
            style={{ cursor: "pointer" }}
          >
            {isPasswordHidden ? <Eye size={24} /> : <EyeSlash size={24} />}
          </div>
        )}

        <div
          className={`${styles.ctaContainer} ${isLoading ? styles.loading : ""}`}
        >
          {isLoading ? (
            <div className="me-4">
              <Spinner size={"12px"} fontSize={"22px"} withBG={false} />
            </div>
          ) : (
            <ArrowUpCircleFill
              size={30}
              className={styles.sendIcon}
              onClick={() => {
                value.length > 0 && onConfirmCB();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TextInput;
