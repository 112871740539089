import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Spinner from "../Spinner/Spinner";
import { getInfo, processAction } from "./API";
import AppContext from "../../context";
import { useNotification } from "../Notification/NotificationProvider";
import { useDispatch } from "react-redux";
import { highlightTask } from "./TaskSlice";

const TaskInviteHandler = () => {
  const [task, setTask] = useState(null); // Хранение информации о задаче
  const dispatch = useDispatch();
  const { isLogined } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const notify = useNotification();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const uuid = searchParams.get("uuid");

    if (uuid) {
      getInfo({ uuid })
        .then((data) => {
          if (data) {
            setTask(data); // Установка информации о задаче
          }

          console.log("isLG", isLogined);
          if (isLogined === true) {
            processAction(uuid, "accept_invite").then((r) => {
              notify({
                message: `${data.title} — приглашение принято!`,
              });
              dispatch(highlightTask(uuid));
            });
            navigate("/m/tasks");
          } else if (isLogined === false) {
            notify({
              message: "Сначала войдите в аккаунт.",
            });
            navigate("/m/tasks");
          }
        })
        .catch((error) => {
          console.error("Ошибка при получении задачи:", error);
          navigate("/m/tasks");
        });
    } else {
      navigate("/m/tasks");
    }
  }, [isLogined]);

  if (!task) {
    return <Spinner withBG={false} />; // Отображение загрузки, пока данные задачи не получены
  }

  return (
    <>
      <Helmet>
        <title>{task.title ? `${task.title}` : "Приглашение"}</title>
        <meta
          name="description"
          content={"Присоединитесь к этому событию в один клик"}
        />

        {/* Мета-теги Open Graph */}
        <meta property="og:title" content={task.title || "Приглашение"} />
        <meta
          property="og:description"
          content={"Присоединитесь к этому событию в один клик"}
        />
        <meta property="og:image" content={task.image || "/og.png"} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <div className="h100 d-flex justify-content-center align-items-center">
        <Spinner withBG={true} />
      </div>
    </>
  );
};

export default TaskInviteHandler;
