import React, { useEffect } from "react";
import styles from "./Phone.module.css";
import { Helmet } from "react-helmet";
import { Player } from "@lottiefiles/react-lottie-player";
import additionalLoginAnimation from "../../assets/animations/laptop-login.json";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

function Phone({ staticContext = {} }) {
  staticContext.notFound = true;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isMobile) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Эй Айрнольд! — персональный ассистент</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={styles.root}>
        <div
          className="d-flex flex-column align-content-center justify-content-center m-0 h-100"
          style={{ maxWidth: "360px" }}
        >
          <Player
            autoplay
            speed={0.5}
            src={additionalLoginAnimation}
            style={{ width: "160px", height: "160px" }}
          />
          <h3 className="mt-4 mb-2" style={{ fontSize: "1.8rem" }}>
            Откройте с компа
          </h3>
          <p className="text-center" style={{ lineHeight: "1.3" }}>
            На время беты — Айрнольд работает только с компьютера и через
            Телеграм. Мобильная версия находится в разработке.
          </p>
        </div>
      </div>
    </>
  );
}

export default Phone;
