import React, { createContext, useContext, useState } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./Notification.module.css";
import icon from "../../assets/favicon.svg";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const showNotification = ({
    title = "Айрнольд",
    message,
    timestamp,
    timeout = 8000,
  }) => {
    const id = Date.now();
    setNotifications((prev) => [
      { id, title, message, timestamp, timeout, show: true },
      ...prev,
    ]);
  };

  return (
    <NotificationContext.Provider value={showNotification}>
      {children}
      <ToastContainer
        className={`p-3 ${styles.toastContainer}`}
        position="top-end"
      >
        <TransitionGroup>
          {notifications.map(
            ({ id, title, message, timestamp, show, timeout }) => (
              <CSSTransition
                key={id}
                timeout={300}
                classNames={{
                  enter: styles.toastEnter,
                  enterActive: styles.toastEnterActive,
                  exit: styles.toastExit,
                  exitActive: styles.toastExitActive,
                }}
                onExited={() =>
                  setNotifications((prev) => prev.filter((n) => n.id !== id))
                }
              >
                <Toast
                  onClose={() =>
                    setNotifications((prev) =>
                      prev.map((n) =>
                        n.id === id ? { ...n, show: false } : n,
                      ),
                    )
                  }
                  show={show}
                  autohide={timeout !== null}
                  animation={true}
                  delay={timeout || 3000}
                >
                  <Toast.Header closeVariant="white">
                    <img
                      alt={"airnold logo"}
                      className="me-2"
                      src={icon}
                      width={18}
                    />
                    <strong className="me-auto">{title}</strong>
                    <small>{timestamp}</small>
                  </Toast.Header>
                  <Toast.Body>{message}</Toast.Body>
                </Toast>
              </CSSTransition>
            ),
          )}
        </TransitionGroup>
      </ToastContainer>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
