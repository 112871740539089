import React, { useContext, useEffect } from "react";
import SideBar from "../../components/SideBar/SideBar";
import TaskManagerScreen from "../../components/TaskManager/TaskManagerScreen";

import styles from "./Home.module.css";
import AppContext from "../../context";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import TelegramClientScreen from "../../components/TelegramClient/TelegramClientScreen";
import Spinner from "../../components/Spinner/Spinner";
import { isMobile } from "react-device-detect";

const Home = ({}) => {
  const navigate = useNavigate();

  const { isLogined, setIsLogined } = useContext(AppContext);

  useEffect(() => {
    if (isMobile) {
      navigate("/mobile");
    }

    console.log(window.location);

    if (isLogined === false) {
      navigate("/login");
    } else if (isLogined === true && window.location.pathname === "/") {
      navigate("/m/tasks");
    }
  }, [isLogined]);

  const title = "Эй Айрнольд! — персональный ассистент";
  const description = "Персональный ассинтент нового поколения";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="/static/og.jpg" />
      </Helmet>

      {isLogined === null || isLogined === false ? (
        <Spinner />
      ) : (
        <div className="d-flex flex-row justify-content-center align-items-center w-100 h-100">
          <SideBar />
          <div className={styles.contentContainer}>
            <Routes>
              <Route path="m/tasks" element={<TaskManagerScreen />} />
              <Route path="i/telegram" element={<TelegramClientScreen />} />
              <Route path="*" element={<TaskManagerScreen />} />
              {/* Add more routes as needed */}
            </Routes>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
