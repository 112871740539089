import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTasksList } from "./API"; // Async thunk to fetch tasks

// Async thunk to fetch tasks
export const fetchTasks = createAsyncThunk(
  "tasks/fetchTasks",
  async (date = null) => {
    const { tasksByCategory, sortedCategories } = await getTasksList(date);
    return { tasksByCategory, sortedCategories };
  },
);

const tasksSlice = createSlice({
  name: "tasks",
  initialState: {
    tasksByCategory: {},
    sortedCategories: [],
    loading: true,
    error: null,
    timestamp: null,
    highlightedTasks: [],
  },
  reducers: {
    highlightTask: (state, action) => {
      state.highlightedTasks = [];
      if (!state.highlightedTasks.includes(action.payload)) {
        state.highlightedTasks.push(action.payload); // Add only if unique
      }
    },
    setTimestamp: (state, action) => {
      console.log("setTimestamp", action.payload);
      state.timestamp = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setTasks(state, action) {
      state.tasksByCategory = action.payload.tasksByCategory;
      state.sortedCategories = action.payload.sortedCategories;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.tasksByCategory = action.payload.tasksByCategory;
        state.sortedCategories = action.payload.sortedCategories;
        state.loading = false;
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { highlightTask, setLoading, setTimestamp, setTasks, setError } =
  tasksSlice.actions;

export default tasksSlice.reducer;
