import React, { useState } from "react";

import SelectInput from "../SelectInput/SelectInput";

import healthIcon from "../../assets/icons/taskKind/health.svg";
import workIcon from "../../assets/icons/taskKind/work.svg";
import gameIcon from "../../assets/icons/taskKind/game.svg";
import studyIcon from "../../assets/icons/taskKind/study.svg";
import familyIcon from "../../assets/icons/taskKind/family.svg";
import homeIcon from "../../assets/icons/taskKind/home.svg";

import highPriority from "../../assets/icons/taskPriority/high.svg";
import mediumPriority from "../../assets/icons/taskPriority/medium.svg";
import lowPriority from "../../assets/icons/taskPriority/low.svg";
import { BellSlashFill, Link45deg, PersonFill } from "react-bootstrap-icons";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import styles from "./TaskItem.module.css";
import {
  processAction,
  updatePriority,
  updateReminders,
} from "../TaskManager/API";
import { useNotification } from "../Notification/NotificationProvider";
import { partial } from "lodash";
import { useDispatch } from "react-redux";
import { setLoading } from "../TaskManager/TaskSlice";

const priorityOptions = [
  { value: "high", label: "Высокий", icon: highPriority },
  { value: "medium", label: "Средний", icon: mediumPriority },
  { value: "low", label: "Низкий", icon: lowPriority },
];

const reminderOptions = [
  { value: "NOW", label: "1 C", longLabel: "за секунду до" },
  { value: "MINUTES_15", label: "15 М", longLabel: "за 15 минут до" },
  { value: "HOUR_1", label: "1 Ч", longLabel: "за 1 час до" },
  { value: "HOURS_2", label: "2 Ч", longLabel: "за 2 часа до" },
  { value: "HOURS_10", label: "10 Ч", longLabel: "за 10 часов до" },
  { value: "DAY_1", label: "1 Д", longLabel: "за 1 день до" },
  { value: "DAYS_2", label: "2 Д", longLabel: "за 2 дня до" },
  { value: "WEEK_1", label: "1 Н", longLabel: "за 1 неделю до" },
];

const icon2render = {
  health: { icon: healthIcon, color: "#FF7AAA" },
  work: { icon: workIcon, color: "#8C74EC" },
  game: { icon: gameIcon, color: "#EC9F74" },
  study: { icon: studyIcon, color: "#74ECC8" },
  family: { icon: familyIcon, color: "#ECE074" },
  home: { icon: homeIcon, color: "#74D6EC" },
};

const TaskItem = ({
  id,
  uuid,
  kind,
  start,
  end,
  title,
  reminders,
  priority,
  isCompleted,
  spectator,
  spectatorUsers,
  selectedDate,
  isHighlighted,
}) => {
  const notify = useNotification();
  const dispatch = useDispatch();

  const [inviteCopied, setInviteCopied] = useState(false);

  const { icon, color } = icon2render[kind] || { icon: null, color: "#000" };

  const now = selectedDate;
  const startDate = start && new Date(start);
  const endDate = end && new Date(end);

  const isToday =
    !start ||
    startDate.toDateString() === now.toDateString() ||
    endDate.toDateString() === now.toDateString();

  const formatTime = (date) => {
    return date.toLocaleTimeString("ru-RU", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const displayDate =
    !isToday &&
    startDate.toLocaleDateString("ru-RU", {
      day: "numeric",
      month: "long",
    });

  const handleAction = async (eventUUID, action) => {
    dispatch(setLoading(true));
    console.log("Action", action);
    try {
      await processAction(eventUUID, action);
    } catch (error) {
      console.error("Error saving task:", error);
      notify({
        message:
          "Ой-ой! Во время обновления задачи произошла ошибка. Уже чиним этот баг.",
      });
    }
  };

  const handleReminders = async (eventUUID, reminders) => {
    try {
      await updateReminders(eventUUID, reminders);
    } catch (error) {
      console.error("Error saving task:", error);
      notify({
        message:
          "Ой-ой! Во время обновления напоминаний задачи произошла ошибка. Уже чиним этот баг.",
      });
    }
  };

  const handlePriority = async (eventUUID, priorities) => {
    try {
      await updatePriority(eventUUID, priorities[0]);
    } catch (error) {
      console.error("Error saving task:", error);
      notify({
        message:
          "Ой-ой! Во время обновления приоритетов задачи произошла ошибка. Уже чиним этот баг.",
      });
    }
  };

  const handleCopyInvite = (stringToCopy) => {
    navigator.clipboard.writeText(stringToCopy).then(() => {
      setInviteCopied(true);
      notify({
        message: "Ссылка на событие скопирована.",
      });
      setTimeout(() => setInviteCopied(false), 5000); // Reset the copied state after 2 seconds
    });
  };

  return (
    <div
      className={`d-flex flex-row justify-content-between align-items-center ${styles.container}`}
    >
      <div
        className={`${styles.containerLight} ${isCompleted ? styles.isCompleted : ""} ${isHighlighted ? styles.pulseHighlight : ""}`}
        style={{ "--icon-color": color }}
      />

      <div
        className={`d-flex flex-row justify-content-start align-items-center ${styles.taskInfoContainer}`}
      >
        <div className={styles.iconContainer}>
          <img alt={`${kind} icon`} src={icon} />
        </div>
        <>
          <span
            className={`${styles.title} ${isCompleted ? styles.isCompleted : ""}`}
          >
            {title}
          </span>
        </>
      </div>
      {startDate && (
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          style={{ minWidth: "190px", zIndex: 4, cursor: "pointer" }}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                {spectatorUsers && spectatorUsers.length > 0 && (
                  <span>
                    В событии участвуют: {spectatorUsers.join(", ")}
                    <br />
                    <br />
                  </span>
                )}
                Чтобы пригласить друзей, нажмите на эту кнопку
              </Tooltip>
            }
          >
            <div
              className={`${styles.infoContainer} me-2`}
              style={{ minWidth: "60px" }}
              onClick={() => {
                handleCopyInvite(
                  `${window.location.origin}/m/tasks/invite?uuid=${uuid}`,
                );
              }}
            >
              {spectatorUsers && spectatorUsers.length > 0 ? (
                <span>
                  <PersonFill
                    color="#FBB040"
                    size={16}
                    className="me-2"
                    style={{ margin: "-3px 0 0 -2px" }}
                  />
                  {spectatorUsers.length}
                </span>
              ) : (
                <Link45deg
                  className={`${styles.inviteLinkLogo} ${inviteCopied ? styles.copied : ""}`}
                  size={20}
                  style={{ margin: "-2px 0 0 0" }}
                />
              )}
            </div>
          </OverlayTrigger>

          <>
            {isToday ? (
              <div>
                <div
                  className={`${styles.infoContainer}`}
                  style={{ minWidth: "130px" }}
                >
                  <span>
                    {formatTime(startDate)} - {formatTime(endDate)}
                  </span>
                </div>
              </div>
            ) : (
              <div className={`${styles.infoContainer}`}>
                <span>
                  {displayDate} в {formatTime(startDate)}{" "}
                </span>
              </div>
            )}
          </>
        </div>
      )}

      <div className="d-flex flex-row justify-content-end align-items-center">
        <div
          className={`d-flex flex-row justify-content-start align-items-center ${styles.taskOptionsContainer} `}
        >
          {!isCompleted && (
            <>
              <div className="mx-2">
                <SelectInput
                  options={priorityOptions}
                  defaultValues={[priority]}
                  title={"Приоритет"}
                  isMulti={false}
                  onUpdateCB={partial(handlePriority, uuid)}
                  disabled={spectator}
                />
              </div>
              <SelectInput
                options={reminderOptions}
                defaultValues={reminders}
                title={"Напомнить"}
                isMulti={true}
                minWidth={"74px"}
                deselectAllLogo={<BellSlashFill size={15} />}
                onUpdateCB={partial(handleReminders, uuid)}
                disabled={!start || spectator}
              />
            </>
          )}
        </div>
        <div
          className={`d-flex flex-row align-items-center justify-content-end ${styles.actionsContainer}`}
        >
          {!isCompleted && start && !spectator && (
            <OverlayTrigger
              overlay={
                <Tooltip>
                  <span>На следующий день</span>
                </Tooltip>
              }
            >
              <Button
                onClick={() => {
                  handleAction(uuid, "repeat").then((r) => null);
                }}
                variant="text"
                className={styles.actionButton}
                style={{ marginRight: "4px" }}
              >
                <div className={styles.actionImageContainer}>
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 500 500"
                  >
                    <path
                      className={styles.repeatLogo}
                      fill="#9CA0A1"
                      d="M449.73,188.44l-.15-.32c-6.17-12.11-20.85-17.55-33.42-12.38-13.41,5.51-19.83,20.9-14.32,34.31l.14.32c10.11,22.01,15.23,45.46,15.24,69.68.03,92.18-74.93,167.21-167.12,167.25h-.07c-44.63,0-86.6-17.37-118.17-48.92-31.59-31.56-48.99-73.54-49.01-118.2-.03-89.26,70.26-162.44,158.41-167.02v42.64c0,4.08,1.44,8.06,4.05,11.19,2.99,3.59,7.2,5.8,11.86,6.23.54.05,1.08.07,1.62.07,4.08,0,7.99-1.41,11.17-4.05l83.02-69.16c4-3.34,6.3-8.23,6.3-13.44s-2.3-10.1-6.3-13.44L269.96,4.05C266.82,1.44,262.85,0,258.77,0h0C254.09,0,249.7,1.82,246.39,5.12c-3.31,3.3-5.13,7.7-5.13,12.37v43.06c-28.77,1.12-56.63,7.77-82.94,19.85-53.35,24.49-93.97,68.29-114.38,123.32-20.41,55.04-18.16,114.73,6.33,168.08,24.49,53.35,68.29,93.97,123.32,114.38,24.9,9.23,50.73,13.83,76.52,13.83,31.22,0,62.35-6.74,91.56-20.15,53.35-24.49,93.97-68.29,114.38-123.32,20.41-55.04,18.16-114.73-6.33-168.08ZM349.74,82.92s0,0,0,0h0s0,0,0,0Z"
                    />
                  </svg>
                </div>
              </Button>
            </OverlayTrigger>
          )}
          <OverlayTrigger
            overlay={
              <Tooltip>
                {spectator ? (
                  <span>Отписаться от события</span>
                ) : (
                  <>
                    {!isCompleted ? (
                      <span>Удалить событие</span>
                    ) : (
                      <span>Скрыть событие</span>
                    )}
                  </>
                )}
              </Tooltip>
            }
          >
            <Button
              onClick={() => {
                handleAction(uuid, "cancel").then((r) => null);
              }}
              variant="text"
              className={styles.actionButton}
            >
              <div className={styles.actionImageContainer}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    className={styles.cancelLogo}
                    fill="#9CA0A1"
                    d="M.282.563a.96.96 0 0 1 1.359 0l5.078 5.08 5.078-5.08a.96.96 0 1 1 1.36 1.359L8.075 7l5.08 5.079a.962.962 0 0 1-1.359 1.358L6.72 8.357l-5.078 5.08A.96.96 0 1 1 .28 12.08L5.363 7 .282 1.922a.96.96 0 0 1 0-1.359Z"
                  />
                </svg>
              </div>
            </Button>
          </OverlayTrigger>
          {!isCompleted && !spectator && (
            <OverlayTrigger
              overlay={
                <Tooltip>
                  <span>Завершить задачу</span>
                </Tooltip>
              }
            >
              <Button
                onClick={() => {
                  handleAction(uuid, "complete").then((r) => null);
                }}
                variant="text"
                className={styles.actionButton}
              >
                <div className={styles.actionImageContainer}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="11"
                    fill="none"
                    viewBox="0 0 15 11"
                  >
                    <path
                      fill="#30FF76"
                      d="M13.276.285a1.046 1.046 0 0 1 1.428 0c.39.374.395.978.013 1.359L6.654 10.69a.998.998 0 0 1-.327.225 1.043 1.043 0 0 1-1.125-.199L.295 5.997A.955.955 0 0 1 0 5.311c0-.256.106-.502.295-.686a1.046 1.046 0 0 1 1.428 0l4.162 4.003L13.249.313l.027-.028Z"
                    />
                  </svg>
                </div>
              </Button>
            </OverlayTrigger>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskItem;
