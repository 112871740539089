import React, { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { ru } from "date-fns/locale";
import styles from "./CalendarWidget.module.css";
import { Button } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";

export const formatDate = (date) => {
  const options = { month: "long", day: "numeric" };
  return new Intl.DateTimeFormat("ru-RU", options).format(date);
};

const CalendarWidget = ({ selectedDate, setSelectedDate }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);

  const toggleCalendar = () => setShowCalendar(!showCalendar);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowCalendar(false);
  };

  const handlePreviousDay = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  };

  const handleNextDay = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  };

  // Close calendar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);

  return (
    <div className={styles.calendarWidget} ref={calendarRef}>
      <div
        className={`d-flex justify-content-between align-items-center ${styles.calendarHeader}`}
      >
        <Button
          className={styles.btnLink}
          variant="transparent"
          onClick={handlePreviousDay}
        >
          <ChevronLeft size={12} />
        </Button>
        <span className={styles.calendarDate} onClick={toggleCalendar}>
          {selectedDate.toDateString() === new Date().toDateString()
            ? "Сегодня"
            : formatDate(selectedDate)}
        </span>
        <Button
          className={styles.btnLink}
          variant="transparent"
          onClick={handleNextDay}
        >
          <ChevronRight size={12} />
        </Button>
      </div>
      {showCalendar && (
        <div className={styles.calendarPopup}>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            inline
            locale={ru} // Set the locale for DatePicker
          />
        </div>
      )}
    </div>
  );
};

export default CalendarWidget;
