import React, { useContext, useEffect } from "react";

import styles from "./UserProfile.module.css";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BoxArrowRight } from "react-bootstrap-icons";
import { handleLogout } from "../../pages/Login/API";
import AppContext from "../../context";
import { useDispatch, useSelector } from "react-redux";
import { profileError, profileLoading, setProfile } from "./ProfileSlice";
import axios from "axios";
import { API_ROOT } from "../../const/api";
import Spinner from "../Spinner/Spinner";
import { useNotification } from "../Notification/NotificationProvider";

const UserProfile = ({}) => {
  const { isLogined, setIsLogined } = useContext(AppContext);
  const notify = useNotification();

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profile);
  const status = useSelector((state) => state.profile.status);
  const error = useSelector((state) => state.profile.error);

  useEffect(() => {
    if (isLogined !== true) {
      return;
    }

    const fetchProfile = async () => {
      dispatch(profileLoading());
      try {
        const response = await axios.get(`${API_ROOT}/api/profile/get`, {
          withCredentials: true,
        });
        dispatch(setProfile(response.data));
      } catch (err) {
        dispatch(profileError(err.message));
      }
    };

    fetchProfile().then((r) => null);
  }, [dispatch, isLogined]);

  return (
    <div
      className="position-relative d-flex flex-row justify-content-between align-items-center"
      style={{ minHeight: "37px" }}
    >
      {status !== "succeeded" ? (
        <div className="mb-4">
          <Spinner withBG={false} />
        </div>
      ) : (
        <>
          <div className="d-flex flex-row justify-content-center align-items-center w-100 overflow-hidden">
            <div className={styles.imagePlaceholderContainer}>
              <span className={`text-secondary ${styles.imagePlaceholderText}`}>
                {profile.full_name ? profile.full_name[0].toUpperCase() : "-"}
              </span>
            </div>
            <div className="d-flex flex-column align-items-start justify-content-center w-100 overflow-hidden">
              <div>
                {profile.full_name && (
                  <h4 className={`smooth m-0 ${styles.title}`}>
                    {profile.full_name}
                  </h4>
                )}
              </div>
              <div>
                <p className={`m-0 ${styles.subtitle}`}>
                  ID {profile.profile_id}
                </p>
              </div>
            </div>
          </div>
          <div>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  <span>Выйти из аккаунта</span>
                </Tooltip>
              }
            >
              <Button
                onClick={() => {
                  console.log("logout");
                  handleLogout().then((response) => {
                    console.log("set is logined false");
                    setIsLogined(false);
                    notify({
                      message: "Разлогинил вас, до новых встреч.",
                    });
                  });
                }}
                variant="text"
              >
                <BoxArrowRight
                  color="#979797"
                  size={20}
                  style={{ marginTop: "-4px" }}
                />
              </Button>
            </OverlayTrigger>
          </div>
        </>
      )}
    </div>
  );
};

export default UserProfile;
