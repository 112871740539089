import React, { useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import toggleTriangle from "../../assets/icons/toggleTriangle.svg";
import { Check, SlashCircleFill } from "react-bootstrap-icons";
import styles from "./SelectInput.module.css";
import { Button } from "react-bootstrap";

const SelectInput = ({
  options,
  defaultValues = [],
  title = null,
  isMulti = false,
  minWidth = "66px",
  deselectAllLogo = null,
  onUpdateCB = null,
  disabled = false,
}) => {
  const findOptionByValue = (value) =>
    options.find((option) => option.value === value);

  const [selectedOptions, setSelectedOptions] = useState(defaultValues);
  const [optionToView, setOptionToView] = useState(
    findOptionByValue(defaultValues[0]),
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (eventKey) => {
    const selected = findOptionByValue(eventKey);

    if (!selected) return;

    setSelectedOptions((prevSelected) =>
      isMulti
        ? prevSelected.includes(selected.value)
          ? prevSelected.filter((value) => value !== selected.value)
          : [...prevSelected, selected.value]
        : [selected.value],
    );

    if (!isMulti) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (selectedOptions.length > 0) {
      // Maintain the order of selected options as per the original options array
      const orderedSelection = options.filter((option) =>
        selectedOptions.includes(option.value),
      );
      setOptionToView(orderedSelection[0]);
    } else {
      setOptionToView(null);
    }

    if (!isDropdownOpened) {
      return;
    }

    onUpdateCB && onUpdateCB(selectedOptions).then((r) => {});
  }, [selectedOptions, options]);

  useEffect(() => {
    setIsDropdownOpened(true);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <Dropdown
      ref={dropdownRef}
      onSelect={handleSelect}
      show={showDropdown}
      onToggle={(isOpen) => {
        if (!isMulti) setShowDropdown(isOpen);
      }}
    >
      <Dropdown.Toggle
        variant="outline"
        id="dropdown-basic"
        disabled={disabled}
        style={{ minWidth: minWidth, position: "relative" }}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {selectedOptions.length > 1 && (
          <span className={styles.moreOptionsBadge}>
            {/*+{selectedOptions.length - 1}*/}
          </span>
        )}
        {optionToView ? (
          <>
            {optionToView.icon ? (
              <img
                key={optionToView.value}
                src={optionToView.icon}
                alt={optionToView.label}
                className={styles.selectedIcon}
              />
            ) : (
              <span>{optionToView.label}</span>
            )}
          </>
        ) : (
          <span style={{ marginTop: "-3px" }}>
            {deselectAllLogo ? (
              <>{deselectAllLogo}</>
            ) : (
              <SlashCircleFill size={15} />
            )}
          </span>
        )}
        <img
          src={toggleTriangle}
          alt="toggle-triangle"
          className={styles.caretIcon}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Dropdown.ItemText>
            {title && <span>{title}</span>}
            {deselectAllLogo && (
              <Button
                onClick={() => {
                  setSelectedOptions([]);
                  setShowDropdown(false);
                }}
                variant="text"
                style={{
                  color: "white",
                  padding: "0 4px 2px",
                  marginRight: "-4px",
                  marginTop: "-3px",
                }}
              >
                {deselectAllLogo}
              </Button>
            )}
          </Dropdown.ItemText>
        </div>

        {options.map((option) => (
          <Dropdown.Item
            key={option.value}
            eventKey={option.value}
            className={`d-flex align-items-center justify-content-between ${styles.dropdownItem}`}
          >
            <div className="d-flex align-items-center">
              {option.icon && (
                <img
                  src={option.icon}
                  alt={option.label}
                  className={styles.icon}
                />
              )}
              {option.longLabel ? (
                <span>{option.longLabel}</span>
              ) : (
                <span>{option.label}</span>
              )}
            </div>
            {selectedOptions.includes(option.value) && (
              <Check size={20} color="#30FF76" />
            )}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectInput;
