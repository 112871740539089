import { createSlice } from "@reduxjs/toolkit";

// Initial state for the profile
const initialState = {
  profile: null, // This will hold the user's profile data
  status: "idle", // Status can be 'idle', 'loading', 'succeeded', 'failed'
  error: null, // To store any errors
};

// Create the slice
const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    // Action to set the profile
    setProfile(state, action) {
      state.profile = action.payload;
      state.status = "succeeded";
    },
    // Action to clear the profile (e.g., on logout)
    clearProfile(state) {
      state.profile = null;
      state.status = "idle";
    },
    // Action to handle loading state
    profileLoading(state) {
      state.status = "loading";
    },
    // Action to handle errors
    profileError(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

// Export the actions generated by createSlice
export const { setProfile, clearProfile, profileLoading, profileError } =
  profileSlice.actions;

// Export the reducer to include it in the store
export default profileSlice.reducer;
