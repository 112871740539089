import { createSlice } from "@reduxjs/toolkit";

const telegramSlice = createSlice({
  name: "telegram",
  initialState: {
    loginState: "initial", // initial, qrCode, otpInput, success, failed
    otpHint: "",
    errorMessage: "", // To store error messages, if any
  },
  reducers: {
    showWelcomeScreen(state) {
      state.loginState = "welcomeScreen";
    },
    showQrCode(state) {
      state.loginState = "qrCode";
      state.errorMessage = "";
    },
    showOtpInput(state) {
      state.loginState = "otpInput";
      state.errorMessage = "";
    },
    telegramLoginSuccess(state) {
      state.loginState = "success";
    },
    telegramLoginFailed(state, action) {
      state.loginState = "failed";
      state.errorMessage = action.payload || null;
    },
    clearLoginState(state) {
      state.loginState = "initial";
      state.errorMessage = "";
      state.otpHint = "";
    },
    updateOtpHint(state, action) {
      state.otpHint = action.payload;
    },
    updateErrorHint(state, action) {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  showWelcomeScreen,
  showQrCode,
  showOtpInput,
  telegramLoginSuccess,
  telegramLoginFailed,
  clearLoginState,
  updateOtpHint,
  updateErrorHint,
} = telegramSlice.actions;

export default telegramSlice.reducer;
