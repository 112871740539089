import React from "react";

import styles from "./Spinner.module.css";

const Spinner = ({ size = "50px", fontSize = "28px", withBG = true }) => {
  return (
    <div
      className={`${styles.container} ${withBG ? styles.bg : ""}`}
      style={{ width: `${size}`, height: `${size}` }}
    >
      <div
        className={`${styles.spinner} ${styles.center}`}
        style={{ fontSize: `${fontSize}` }}
      >
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
      </div>
    </div>
  );
};

export default Spinner;
