import { configureStore } from "@reduxjs/toolkit";
import profileReducer from "./components/UserProfile/ProfileSlice";
import tasksReducer from "./components/TaskManager/TaskSlice"; // Create the Redux store and include the profile slice
import telegramClientReducer from "./components/TelegramClient/TelegramClientSlice"; // Create the Redux store and include the profile slice

// Create the Redux store and include the profile slice
const store = configureStore({
  reducer: {
    profile: profileReducer,
    tasks: tasksReducer,
    telegram: telegramClientReducer,
  },
});

export default store;
