// NoInternet.js
import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../assets/animations/temperature.json";
import { Helmet } from "react-helmet";

const NoInternet = () => {
  const title = "Нет интернета | Эй Айрнольд!";
  const description = "Пожалуйста проверьте подключение к интернету";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="/static/og.jpg" />
      </Helmet>
      <div className="h-100 w-100 d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column align-content-center justify-content-center my-5">
          <Player
            autoplay
            src={animationData}
            style={{ width: "160px", height: "160px" }}
          />
          <h3 className="mt-4 mb-3" style={{ fontSize: "1.8rem" }}>
            Интернет температурит
          </h3>
          <p className="text-center" style={{ lineHeight: "1.3" }}>
            Что-то не так с подключением к интернету,
            <br />
            проверьте ВПН и работу других сайтов.
          </p>
        </div>
      </div>
    </>
  );
};

export default NoInternet;
